import { FC, useEffect, useState } from 'react';
import { ModalClientDetails } from '~/components';
import { isEmpty, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { useStores } from '~/utils';
import ClientAccounts from './ClientAccounts';

const ClientAccountsContainer: FC = () => {
  const navigate = useNavigate();

  const {
    clients: { currentClient, setSelectedAccount },
    products: { setCurrentProduct },
  } = useStores();

  const [isModalClientDetailsOpen, setIsModalClientDetailsOpen] =
    useState(false);

  const handleNavigate = (route: Routes) => navigate(route);

  const handleModalClientDetails = () =>
    setIsModalClientDetailsOpen((value) => !value);

  const onPressAccount = (account: Account) => {
    setSelectedAccount(account);
    setCurrentProduct(account.product!);
    navigate(Routes.CLIENT_ACCOUNT_DETAILS);
  };

  useEffect(() => {
    if (isEmpty(currentClient)) {
      navigate(Routes.CLIENT_SEARCH);
    }
  }, [currentClient]);

  return (
    <>
      <ClientAccounts
        client={currentClient}
        onShowClientData={handleModalClientDetails}
        onPressAccount={onPressAccount}
        onNavigate={handleNavigate}
      />
      <ModalClientDetails
        dataClient={currentClient}
        isOpen={isModalClientDetailsOpen}
        handleClose={handleModalClientDetails}
      />
    </>
  );
};

export default observer(ClientAccountsContainer);
