import { SceneWrapper, Typography } from '~/components';
import { getTheme, styled } from '~/modules';
import { mqDevices } from '~/utils';

const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  padding: ${spacingXxl}px 0;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const PaginatedListBox = styled.div`
  flex: 1;
  flex-direction: column;
  margin-left: ${spacingLg}px;

  @media ${mqDevices.inMobileAndTablet} {
    margin-left: 0;
    margin-top: ${spacingXxl}px;
  }
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
`;
